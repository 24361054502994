(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/betslip-drawer-content/assets/javascripts/betslip-drawer-content.js') >= 0) return;  svs.modules.push('/components/tipsen/betslip-drawer-content/assets/javascripts/betslip-drawer-content.js');
"use strict";

var _svs$core$config$data, _svs$core$config$data2;

const {
  ReactIcon
} = svs.ui;
const {
  CouponOverview
} = svs.components.tipsen.couponOverview;
const {
  Betslip
} = svs.components.tipsen.betslipComponents;
const isSelfService = (_svs$core$config$data = (_svs$core$config$data2 = svs.core.config.data.svsconfig) === null || _svs$core$config$data2 === void 0 ? void 0 : _svs$core$config$data2.isSelfService) !== null && _svs$core$config$data !== void 0 ? _svs$core$config$data : false;
const {
  useSelector
} = ReactRedux;
const {
  useCouponId
} = svs.components.tipsen.couponId;
const {
  selectSystemType,
  selectIsCouponSingleRows,
  selectIsCouponExternal
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  SYSTEM_REDUCERA_FRITT
} = svs.components.tipsen.engine.constants.systems;
const {
  ShareCoupon
} = svs.components.tipsen.shareCoupon;
const BetslipDrawerContent = _ref => {
  let {
    handleOpen = () => {},
    handleOpenOrClose = () => {},
    style = {}
  } = _ref;
  const couponId = useCouponId();
  const systemType = useSelector(state => selectSystemType(state, couponId));
  const isSingleRows = useSelector(state => selectIsCouponSingleRows(state, couponId));
  const isExternal = useSelector(state => selectIsCouponExternal(state, couponId));
  const showShareCoupon = systemType !== SYSTEM_REDUCERA_FRITT && !isSingleRows && !isExternal;
  return React.createElement("div", {
    className: "pg_betslip_drawer_content",
    onClick: handleOpen,
    style: style
  }, React.createElement("div", {
    className: "pg_betslip_drawer_content__tab"
  }, React.createElement("div", {
    className: "pg_betslip_drawer_content__tab__line"
  })), React.createElement("div", {
    className: "pg_betslip_drawer_content__container"
  }, React.createElement("div", {
    className: "pg_betslip_drawer_content__container__header",
    onClick: handleOpenOrClose
  }, React.createElement(ReactIcon, {
    className: "pg_betslip_drawer_content__container__header__icon",
    color: "fc-white",
    icon: "menu-down",
    size: "200"
  }), React.createElement("div", {
    className: "pg_betslip_drawer_content__container__header__text"
  }, "Kupong\xF6versikt")), React.createElement("div", {
    className: "pg_betslip_drawer_content__container__coupon_overview"
  }, React.createElement(CouponOverview, {
    isCompact: true
  })), React.createElement("div", {
    className: "pg_betslip_drawer_content__container__bottom"
  }, React.createElement(Betslip.BalanceView, null), !isSelfService && showShareCoupon && React.createElement(ShareCoupon, {
    className: "margin-top-1 pg_betslip__share_coupon"
  }))));
};
setGlobal('svs.components.tipsen.betslipDrawerContent.BetslipDrawerContent', BetslipDrawerContent);

 })(window);